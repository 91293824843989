"use client"

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Game } from '@/lib/schema'
import Image from 'next/image'

function AdminGameSelector({ games, setGame, selectedGame }: ({ games: Game[], setGame: (g: string) => void, selectedGame: string })) {

    const handleChange = (value: string) => {
        setGame(value)
    }

    return (
        <Select value={selectedGame} onValueChange={handleChange}>
            <SelectTrigger className="p-2 border rounded max-w-[250px]">
                <SelectValue placeholder="Select Game" />
            </SelectTrigger>
            <SelectContent>
                {games.map(game => (
                    <SelectItem key={game.name} value={String(game.gameId)}>
                        <div className='flex text-sm '>
                            <Image src={game.icon} alt={game.name} width={20} height={20} className='rounded-full mr-2' />
                            {game.name}
                        </div>
                    </SelectItem>
                ))}
            </SelectContent>
        </Select>
    )
}

export default AdminGameSelector
