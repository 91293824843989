import { useAuth } from '@clerk/nextjs'
import useSWR from 'swr'

export default function useClerkSWR(url: string) {
    const { getToken } = useAuth()

    const fetcher = async (...args: [RequestInfo]) => {
        const token = await getToken()
        if (!token) return null

        return fetch(...args, {
            headers: { Authorization: `Bearer ${token}` },
        }).then((res) => res.json())
    }

    return useSWR(url, fetcher)
}