"use client"

import Image from 'next/image'
import { Tabs, TabsList, TabsTrigger } from './ui/tabs'

function AdminNetworkSelector({ network, selectedNetwork, setSelectedNetwork }: ({ network: string, selectedNetwork: string, setSelectedNetwork: (network: string) => void })) {
    const networks = [
        { name: "Base", value: "base", icon: "/84532.png" },
        // { name: "Ethereum", value: "ethereum", icon: "/1.png" }
    ]

    const handleNetworkChange = (value: string) => {
        setSelectedNetwork(value)
    }

    const isSingleNetwork = networks.length === 1

    return (
        <Tabs defaultValue={network} className="md:col-start-3 col-start-2 col-span-2" onValueChange={handleNetworkChange}>
            <TabsList className={`grid w-full ${isSingleNetwork ? 'grid-cols-1' : 'grid-cols-2'}`} defaultValue={selectedNetwork}>
                {networks.map(({ name, value, icon }) => (
                    <TabsTrigger name={value} value={value} key={name}>
                        {name}
                        <Image src={icon} alt="Network Icon" width={20} height={20} className="ml-4" priority={true} />
                        <input readOnly name="itemId" className="hidden" defaultValue={value} />
                    </TabsTrigger>
                ))}
            </TabsList>
        </Tabs>
    )
}

export default AdminNetworkSelector
