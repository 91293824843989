"use client";

import { Game, Token, Tournament } from '@/lib/schema';
import { refreshTournaments } from '@/server-actions/refresh-tournaments';
import { HelpCircle } from 'lucide-react';
import { ChangeEvent, useState } from 'react';
import AdminNetworkSelector from '../AdminNetworkSelector';
import AdminTokenSelector from '../AdminTokenSelector';
import AdminGameSelector from '../DropdownGameSelector';
import { TournamentTooltip } from '../Tooltip';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { useToast } from '../ui/use-toast';
import { DatePicker } from './Calendar';
import { CreateTournamentProps, handleCreateNewTournament } from './server-actions/CreateTournament';
import { handleStartNewTournament } from './server-actions/StartTournament';

const METACADE = "0xA169a1B44cb47c1A988F92e17470A9A87C8c3c09"
const TOURNAMENT_CONTRACT = "0x5c83eCc729131509e0dd1589A56b8395396db3F3"
type TierState = string[];

let shareholders = [
    { wallet: METACADE, share: 50 },
    { wallet: TOURNAMENT_CONTRACT, share: 50 }, // Contract takes 50% of the share
];

export const ALLOWLIST_KEY = (tournamentId: number | undefined) => {
    return `${process.env.NEXT_PUBLIC_API_ENDPOINT}/allowlist?id=${tournamentId}`;
}

function CreateTournament({ tournaments, games, tokens }: { tournaments: Tournament[], games: Game[], tokens: Token[] }) {
    const { toast } = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const [tiers, setTiers] = useState<TierState>([
        "30", // $0.10~
        "300", // $1~
        "1500" // $5~
    ]);
    const [selectedToken, setSelectedToken] = useState<string>('')
    const [selectedGame, setSelectedGame] = useState<string>('')
    const [selectedTokenDecimals, setSelectedTokenDecimals] = useState<number>(18)
    const [selectedNetwork, setSelectedNetwork] = useState<string>('base')

    // timestamp 7 days from now
    const oneWeekFromNow = new Date();
    oneWeekFromNow.setDate(oneWeekFromNow.getDate() + 7);
    const [date, setDate] = useState<Date>(oneWeekFromNow)
    const latestTournamentByTimestamp = tournaments.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())[0];
    const nextTournamentId = latestTournamentByTimestamp ? latestTournamentByTimestamp.tournamentId + 1 : 1;
    const [tournamentId, setTournamentId] = useState(nextTournamentId);

    const handleTierChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
        const newTiers = [...tiers];
        newTiers[index] = event.target.value;
        setTiers(newTiers);
    };

    const handleCreateTournament = async () => {
        try {
            setIsLoading(true)

            const creditPackages = { tier1: tiers[0], tier2: tiers[1], tier3: tiers[2] };
            const requestInput: CreateTournamentProps = {
                tournamentId,
                gameId: +selectedGame,
                creditBurnRate: 10000,
                baseCreditCost: 1,
                paymentTokenDecimals: selectedTokenDecimals,
                network: selectedNetwork,
                tokenContractAddress: selectedToken,
                shareholders,
                creditPackages
            }
            await handleCreateNewTournament({
                createTournamentProps: requestInput
            })

            toast({
                title: 'Success',
                description: `Successfully created tournament`,
            })
            await refreshTournaments(selectedNetwork)
        } catch (error) {
            console.error(error)
            toast({
                title: 'Error',
                description: 'Failed to create tournament',
            })
        } finally {
            setIsLoading(false)
        }
    }

    const handleStartTournament = async () => {
        try {
            setIsLoading(true)
            await handleStartNewTournament({
                startTournamentProps: { tournamentId }
            })
            toast({
                title: 'Success',
                description: `Successfully updated tournament`,
            })
            await refreshTournaments(selectedNetwork)
        } catch (error) {
            console.error(error)
            toast({
                title: 'Error',
                description: 'Failed to updated tournament',
            })
        } finally {
            setIsLoading(false)
        }
    }

    const handleTournamentIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // ensure number is positive
        if (+event.target.value < 0) {
            return;
        }
        if (isNaN(+event.target.value)) {
            return;
        }
        // ensure this number is not already taken
        if (tournaments.find(tournament => tournament.tournamentId === +event.target.value)) {
            return;
        }
        setTournamentId(+event.target.value);
    };


    return (
        <div className="flex flex-col  py-4 space-y-4">
            <div className="flex flex-col space-y-6 mt-4 text-left">
                <div className='flex space-x-2 items-center'>
                    <Label htmlFor="tournament-id" className="text-right mt-1">
                        Tournament
                    </Label>
                    <Input
                        id="tournament-id"
                        value={tournamentId}
                        onChange={handleTournamentIdChange}
                        className="w-full max-w-xs"
                    />
                    <TournamentTooltip text='This should be the next available tournament ID'>
                        <HelpCircle className="text-white mt-2" height={25} width={25} />
                    </TournamentTooltip>
                </div>
                <div className='flex items-center space-x-2'>
                    <Label htmlFor="network" className="text-right mt-1">
                        Network
                    </Label>
                    <AdminNetworkSelector network='base' selectedNetwork={selectedNetwork} setSelectedNetwork={setSelectedNetwork} />
                </div>
                <div className='flex items-center space-x-2'>
                    <Label htmlFor="game" className="text-right mt-1">
                        Game
                    </Label>
                    <AdminGameSelector games={games} selectedGame={selectedGame} setGame={setSelectedGame} />
                </div>
                <div className='flex items-center space-x-2'>
                    <Label htmlFor="token" className=" text-right mt-1">
                        Token
                    </Label>
                    <AdminTokenSelector tokens={tokens} selectedToken={selectedToken} setToken={setSelectedToken} setSelectedTokenDecimals={setSelectedTokenDecimals} selectedNetwork={selectedNetwork} />
                </div>
                <div className='flex items-center space-x-2'>
                    <Label htmlFor="credit-tiers" className=" text-right">
                        Credit Tiers
                    </Label>
                    <Input
                        id="tiers-1"
                        value={tiers[0]}
                        onChange={(event) => handleTierChange(0, event)}
                        className="w-full max-w-xs"
                    />
                    <Input
                        id="tiers-2"
                        value={tiers[1]}
                        onChange={(event) => handleTierChange(1, event)}
                        className="w-full max-w-xs"
                    />
                    <Input
                        id="tiers-3"
                        value={tiers[2]}
                        onChange={(event) => handleTierChange(2, event)}
                        className="w-full max-w-xs"
                    />
                </div>
                <div className='flex items-center space-x-2'>
                    <p className="w-32 text-right mt-2">Tournament End</p>
                    <DatePicker date={date} setDate={setDate} />
                </div>
                <div className='space-x-2 flex justify-center'>
                    <Button onClick={async () => await handleCreateTournament()} disabled={isLoading} className="w-full min-w-[150px]">
                        {isLoading ? 'Loading...' : 'Create'}
                    </Button>
                    <Button onClick={async () => await handleStartTournament()} disabled={isLoading || status != 'PENDING'} className="w-full min-w-[150px]">
                        {isLoading ? 'Loading...' : 'Start'}
                    </Button>
                </div>
            </div>
        </div>
    );

}

export default CreateTournament;
