"use client"

import {
    Select,
    SelectContent,
    SelectTrigger,
    SelectValue
} from "@/components/ui/select"

export function Dropdown({ children, name }: { children: React.ReactNode, name: string }) {
    return (
        <Select>
            <SelectTrigger className="w-[280px]">
                <SelectValue placeholder={name} />
            </SelectTrigger>
            <SelectContent>
                {children}
            </SelectContent>
        </Select>
    )
}

