"use client"

import { CalendarIcon } from "@radix-ui/react-icons"
import { addDays, format } from "date-fns"

import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover"
import { cn } from "@/lib/utils"
import { Dispatch, SetStateAction } from "react"

export function DatePicker({ date, setDate }: { date: Date; setDate: Dispatch<SetStateAction<Date>> }) {

    const handleDateChange = (date: Date) => {
        setDate(date)
    }

    return (
        <Popover modal={true}>
            <PopoverTrigger asChild>
                <Button
                    variant={"outline"}
                    className={cn(
                        "w-[240px] justify-start text-left font-normal",
                        !date && "text-muted-foreground"
                    )}
                >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {date ? format(date, "PPP") : <span>Pick a date</span>}
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                    mode="single"
                    selected={date}
                    initialFocus
                    onSelect={(date) => { handleDateChange(date as Date) }}
                    disabled={(date) => date < addDays(new Date(), -1)}
                />
            </PopoverContent>
        </Popover>
    )
}
