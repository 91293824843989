"use client"

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Token } from '@/lib/schema'
import Image from 'next/image'

function AdminTokenSelector({ tokens, setToken, selectedToken, selectedNetwork, setSelectedTokenDecimals }: ({ tokens: Token[], setToken: (token: string) => void, selectedToken: string, selectedNetwork: string, setSelectedTokenDecimals: (decimals: number) => void })) {

    const handleChange = (value: string) => {
        setSelectedTokenDecimals(tokens.find(token => token.address.toLowerCase() === value.toLowerCase())?.decimals || 18)
        setToken(value)
    }

    // Ensure MCADE is always at the top of the list for tokens
    tokens.sort((a, b) => a.symbol === "MCADE" ? -1 : b.symbol === "MCADE" ? 1 : 0)

    return (
        <Select value={selectedToken} onValueChange={handleChange}>
            <SelectTrigger className="p-2 border rounded max-w-[250px]">
                <SelectValue placeholder="Select Payment Token" />
            </SelectTrigger>
            <SelectContent>
                {tokens.filter(token => token.network === selectedNetwork.toUpperCase()).map(token => (
                    <SelectItem key={token.symbol} value={token.address || ""}>
                        <div className='flex text-sm space-x-4'>
                            <Image className='mr-2 rounded-full' src={token.imageUrl || ""} height={20} width={20} alt="Token Icon" /> {token.symbol}
                        </div>
                    </SelectItem>
                ))}
            </SelectContent>
        </Select>
    )
}

export default AdminTokenSelector
