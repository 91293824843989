import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/components/ui/tooltip"

export function TournamentTooltip({ children, text }: { children: React.ReactNode, text: string }) {
    return (
        <TooltipProvider>
            <Tooltip delayDuration={100}>
                <TooltipTrigger asChild>
                    {children}
                </TooltipTrigger>
                <TooltipContent side="right">
                    <p className="max-w-32 text-sm font-light">{text}</p>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    )
}
